import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.page.html',
  styleUrls: ['./profile-picture.page.scss'],
})
export class ProfilePicturePage implements OnInit {

  heroList = [];
  selectedPic = '../../assets/icon/icon.png';
  constructor(
    private modalController: ModalController,
    private storage: Storage
  ) {
    let index = 1;
    for (let i = 1; i <= 28; i++) {
      this.heroList.push('../../assets/hero/' + index + '.jpg');
      index++;
    }
  }

  ngOnInit() {
  }

  close() {
    console.log(this.selectedPic);
    this.storage.set('profilePic', this.selectedPic).then(val => {
      this.modalController.dismiss({
        selectedPic: this.selectedPic
      });
    });

    // // this.selectedPic
    // this.modalController.dismiss();
  }

}
