import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Events } from './services/events.service';
import { RestService } from './services/rest.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

  public menu = [
    { title_eng: 'HOME', title: 'หน้าแรก', theme: 'home', icon: 'home' },
    { title_eng: 'LOGIN', title: 'เข้าสู่ระบบ', theme: 'login', icon: 'log-in' },
    { title_eng: 'FISH MARKET', title: 'ตลาดปลา', theme: 'fishList', icon: 'pricetag' },
    { title_eng: 'PRODUCT', title: 'สินค้า', theme: 'productList', icon: 'pricetags' },
    { title_eng: 'ARTICLE', title: 'บทความ', theme: 'blogList', icon: 'newspaper-outline' },
    { title_eng: 'MAGAZINE', title: 'นิตยสาร', theme: 'magazineList', icon: 'book' },
    { title_eng: 'COMIC BOOK', title: 'การ์ตูน', theme: 'cartoonsList', icon: 'create' },
    { title_eng: 'CLIP VIDEO', title: 'คลิปวิดีโอ', theme: 'videosList', icon: 'videocam' },
    { title_eng: 'CART', title: 'ตระกร้าสินค้า', theme: 'cart', icon: 'basket' },
    { title_eng: 'MY ORDER', title: 'รายการที่สั่งซื้อ', theme: 'myorder', icon: 'logo-bitcoin' },
    { title_eng: 'CONTACT US', title: 'ติดต่อเรา', theme: 'contactus', icon: 'chatbox-ellipses-outline' },
    { title_eng: 'CLOSE MENU', title: 'ปิดเมนู', theme: 'close', icon: 'close' },
  ];

  // { title_eng: 'ORDER', title: 'รายการสั่งซื้อ', theme: 'orderList', icon: 'list-box' },

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private iab: InAppBrowser,
    public menuCtrl: MenuController,
    private events: Events,
    private storage: Storage,
    private restApi: RestService,
  ) {
    this.initializeApp();


    events.subscribe('user:login', () => {
      // user and time are the same arguments passed in `events.publish(user, time)`
      this.menu = [
        { title_eng: 'HOME', title: 'หน้าแรก', theme: 'home', icon: 'home' },
        { title_eng: 'PROFILE', title: 'โปรไฟล์', theme: 'login', icon: 'person' },
        { title_eng: 'FISH MARKET', title: 'ตลาดปลา', theme: 'fishList', icon: 'pricetag' },
        { title_eng: 'PRODUCT', title: 'สินค้า', theme: 'productList', icon: 'pricetags' },
        { title_eng: 'ARTICLE', title: 'บทความ', theme: 'blogList', icon: 'newspaper-outline' },
        { title_eng: 'MAGAZINE', title: 'นิตยสาร', theme: 'magazineList', icon: 'book' },
        { title_eng: 'COMIC BOOK', title: 'การ์ตูน', theme: 'cartoonsList', icon: 'create' },
        { title_eng: 'CLIP VIDEO', title: 'คลิปวิดีโอ', theme: 'videosList', icon: 'videocam' },
        { title_eng: 'CART', title: 'ตระกร้าสินค้า', theme: 'cart', icon: 'basket' },
        { title_eng: 'MY ORDER', title: 'รายการที่สั่งซื้อ', theme: 'myorder', icon: 'logo-bitcoin' },
        { title_eng: 'CONTACT US', title: 'ติดต่อเรา', theme: 'contactus', icon: 'chatbox-ellipses-outline' },
        { title_eng: 'CLOSE MENU', title: 'ปิดเมนู', theme: 'close', icon: 'close' },
      ];
    });

    events.subscribe('user:logout', () => {
      // user and time are the same arguments passed in `events.publish(user, time)`
      this.menu = [
        { title_eng: 'HOME', title: 'หน้าแรก', theme: 'home', icon: 'home' },
        { title_eng: 'LOGIN', title: 'เข้าสู่ระบบ', theme: 'login', icon: 'log-in' },
        { title_eng: 'FISH MARKET', title: 'ตลาดปลา', theme: 'fishList', icon: 'pricetag' },
        { title_eng: 'PRODUCT', title: 'สินค้า', theme: 'productList', icon: 'pricetags' },
        { title_eng: 'ARTICLE', title: 'บทความ', theme: 'blogList', icon: 'newspaper-outline' },
        { title_eng: 'MAGAZINE', title: 'นิตยสาร', theme: 'magazineList', icon: 'book' },
        { title_eng: 'COMIC BOOK', title: 'การ์ตูน', theme: 'cartoonsList', icon: 'create' },
        { title_eng: 'CLIP VIDEO', title: 'คลิปวิดีโอ', theme: 'videosList', icon: 'videocam' },
        { title_eng: 'CART', title: 'ตระกร้าสินค้า', theme: 'cart', icon: 'basket' },
        { title_eng: 'MY ORDER', title: 'รายการที่สั่งซื้อ', theme: 'myorder', icon: 'logo-bitcoin' },
        { title_eng: 'CONTACT US', title: 'ติดต่อเรา', theme: 'contactus', icon: 'chatbox-ellipses-outline' },
        { title_eng: 'CLOSE MENU', title: 'ปิดเมนู', theme: 'close', icon: 'close' },
      ];
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.menuCtrl.enable(true);

      this.storage.get('user').then((val) => {
        console.log(val);
        if (val) {
          this.menu = [
            { title_eng: 'HOME', title: 'หน้าแรก', theme: 'home', icon: 'home' },
            { title_eng: 'PROFILE', title: 'โปรไฟล์', theme: 'login', icon: 'person' },
            { title_eng: 'FISH MARKET', title: 'ตลาดปลา', theme: 'fishList', icon: 'pricetag' },
            { title_eng: 'PRODUCT', title: 'สินค้า', theme: 'productList', icon: 'pricetags' },
            { title_eng: 'ARTICLE', title: 'บทความ', theme: 'blogList', icon: 'newspaper-outline' },
            { title_eng: 'MAGAZINE', title: 'นิตยสาร', theme: 'magazineList', icon: 'book' },
            { title_eng: 'COMIC BOOK', title: 'การ์ตูน', theme: 'cartoonsList', icon: 'create' },
            { title_eng: 'CLIP VIDEO', title: 'คลิปวิดีโอ', theme: 'videosList', icon: 'videocam' },
            { title_eng: 'CART', title: 'ตระกร้าสินค้า', theme: 'cart', icon: 'basket' },
            { title_eng: 'MY ORDER', title: 'รายการที่สั่งซื้อ', theme: 'myorder', icon: 'logo-bitcoin' },
            { title_eng: 'CONTACT US', title: 'ติดต่อเรา', theme: 'contactus', icon: 'chatbox-ellipses-outline' },
            { title_eng: 'CLOSE MENU', title: 'ปิดเมนู', theme: 'close', icon: 'close' },
          ];
        }
      });

      this.countUsed();
    });
  }

  countUsed() {
    this.restApi.addCount().then(data => {
      console.log('counted');
    });
  }

  openPage(page) {

    this.menuCtrl.close();
    if (page.theme === 'home') {
      this.router.navigate(['/home']);
    } else if (page.theme === 'cart') {
      this.router.navigate(['/cart']);
    } else if (page.theme === 'orderList') {
      this.router.navigate(['/order-list']);
    } else if (page.theme === 'fishList') {
      this.router.navigate(['/product-list', { dataType: 1, setOf: '' }]);
    } else if (page.theme === 'cartoonsList') {
      this.router.navigate(['/categiries', { dataType: 3 }]);
    } else if (page.theme === 'productList') {
      this.router.navigate(['/product-list', { dataType: 2 }]);
    } else if (page.theme === 'videosList') {
      this.router.navigate(['/product-list', { dataType: 4 }]);
    } else if (page.theme === 'magazineList') {
      this.router.navigate(['/categiries', { dataType: 5 }]);
    } else if (page.theme === 'blogList') {
      this.router.navigate(['/categiries', { dataType: 6 }]);
    } else if (page.theme === 'contactus') {
      this.router.navigate(['/contactus']);
    } else if (page.theme === 'login') {
      this.router.navigate(['/login']);
    } else if (page.theme === 'myorder') {
      this.router.navigate(['/myorder']);
    } else if (page.theme === 'close') {
      this.clickCloseMenu();
    }
  }

  clickline() {
    // console.log("line")
    this.iab.create('https://line.me/R/ti/p/%40goldenbetta', '_system');
  }

  clickYoutube() {
    this.iab.create('https://www.youtube.com/user/maynizaa1', '_system');
  }

  clickCloseMenu() {
    // alert('click menu!!');
    this.menuCtrl.close();
    this.menuCtrl.toggle();
  }
}
