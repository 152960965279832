import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { headers, vaultEndpoint, publicApiKey } from '../../environments/environment';

export interface User {
  uid: string;
  username: string;
  type: string;
  member: string;
  status: string;
  activeFlg: string;
  bussinessField: string;
  profession: string;
  city: string;
  country: string;
  email: string;
  name: string;
  phone: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestService {

  apiUrl = 'https://goldenbettaapp.com/GoldenBetta/api/';
  nodejsApiUrl = 'https://nodejsService.goldenbettaapp.com/';

  // nodejsApiUrl = 'http://localhost:8080/';
  // nodejsApiUrl = 'http://localhost/GoldenBetta/api/';

  currentUser: User;

  constructor(private http2: HTTP, private http: HttpClient) {
    console.log('Hello RestProvider Provider');
    // this.currentLanguage.langId = '1';

  }

  checkout(cart: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');


      this.http.post(this.apiUrl + 'payment_confirm.php', cart, { headers: header })
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  createOrder(order: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.apiUrl + 'create_order.php', order, { headers: header })
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  saveProductOrders(orderList: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.apiUrl + 'payment_confirm.php', orderList, { headers: header })
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getProductList(catId, setOf) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'product_api.php?catId=' + catId + '&setOf=' + setOf).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getCartoonList(typeId) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'cartoon_api.php?typeId=' + typeId).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getCardList() {
    // this.http2.get(this.apiUrl + 'card_api.php', {}, {})
    //   .then(data => {

    //     console.log(data.status);
    //     console.log(data.data); // data received by server
    //     console.log(data.headers);
    //     alert('DATA'+ data);
    //   })
    //   .catch(error => {

    //     console.log(error.status);
    //     console.log(error.error); // error message as string
    //     console.log(error.headers);
    //     alert(error.error);

    //   });
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'card_api.php').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
        alert(err.statusText);
      });
    });
  }

  getOrderDetail(orderNo) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'orderDetail.php?orderNo=' + orderNo).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getVideoList() {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'video_api.php').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getHeaderImg() {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'header_api.php').subscribe(data => {
        // alert('Reveived' + data);
        resolve(data);
      }, err => {
        console.log('XXXXXXXXX' + err.error);
        alert(err.statusText);
      });
    });
  }

  getHeaderImg2() {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'header2_api.php').subscribe(data => {
        // alert('Reveived' + data);
        resolve(data);
      }, err => {
        console.log('XXXXXXXXX' + err.error);
        alert(err.statusText);
      });
    });
  }

  getHeaderImg3() {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'header3_api.php').subscribe(data => {
        // alert('Reveived' + data);
        resolve(data);
      }, err => {
        console.log('XXXXXXXXX' + err.error);
        alert(err.statusText);
      });
    });
  }

  getCartoonImg(cartoonId) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'cartoon_img_api.php?cartoonId=' + cartoonId).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getProductDetail(productId) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'product_detail_api.php?productId=' + productId).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getProductImages(productId) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'product_image_api.php?productId=' + productId).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  register(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.apiUrl + 'register.php', param, { headers: header })
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  login(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'login.php?username=' + param.username + '&password=' + param.password)
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getUser(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'getUser.php?username=' + param.username)
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  updateUser(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.apiUrl + 'updateUser.php', param, { headers: header })
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  addClicks(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'addClicks.php?itemId=' + param.itemId + '&userId=' + param.userId)
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  addLikes(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'addLike.php?itemId=' + param.itemId + '&userId=' + param.userId)
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getCartoonDetail(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'cartoon_detail.php?cartoonId=' + param.cartoonId)
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getCount() {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'get_count.php')
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  addCount() {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'add_count.php')
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getAllTimelline() {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.nodejsApiUrl + 'getAllTimeline')
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  saveTimeline(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.nodejsApiUrl + 'saveTimeline', param, { headers: header })
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  saveComment(param: any) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.post(this.nodejsApiUrl + 'saveComment', param, { headers: header })
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getBanner() {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + 'banner_api.php')
        .subscribe(res => {

          // this.setUserLogin(res);
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  async createTokenCharge(createTokenParam: any, chargeParam: any): Promise<any> {
    const authorization = btoa(publicApiKey);
    headers.Authorization = 'Basic ' + authorization;
    const httpPublic = {
      headers: new HttpHeaders(headers)
    };

    return new Promise((resolve, reject) => {

      this.http.post(vaultEndpoint + 'tokens', createTokenParam, httpPublic).subscribe(
        (data: any) => {
          console.log(data);
          chargeParam.card = data.id;
          this.http.post(this.nodejsApiUrl + 'OmiseCharges', chargeParam).subscribe(
            res => {
              resolve(res);
            },
            error => {
              reject(error);
              console.log('Error', error);

            }

          );
        },
        error => {
          reject(error);
          console.log('Error', error);

        }

      );
    });
  }

  getAPI(path) {
    return new Promise((resolve, reject) => {
      const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');

      this.http.get(this.apiUrl + path)
        .subscribe(res => {

          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

}
