// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: ''
};

export const apiEndpoint = 'https://api.omise.co/';
export const vaultEndpoint = 'https://vault.omise.co/';

export const publicApiKey = 'pkey_test_5jgo18iojr5qclhdn3o';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
